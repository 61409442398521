import { IOrganizationIntegration } from "@smartrr/shared/entities/Integration";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { updateMatchInArray } from "@smartrr/shared/utils/updateMatchInArray";
import { useCallback } from "react";

import { SmartrrVendorSubReducer } from "@vendor-app/app/_state/typedVendorRedux";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { setShopGlobals } from "@vendor-app/utils/shopify/getShopUrlFromGlobals";

import { ISmartrrState } from "..";

export interface IVendorOrganizationsReducerState {
  isLoading: boolean;
  isLoadingIntegrations: boolean;
  billingTimeUpdating: boolean;
  billingRetriesConfigUpdating: boolean;
  errorMessage: string | null;
  activeOrganizationId: string | null;
  activeOrganizationIntegrations: IOrganizationIntegration[];
  organizations: IOrganization[];
}

const initialState: IVendorOrganizationsReducerState = {
  isLoading: true,
  isLoadingIntegrations: false,
  billingTimeUpdating: false,
  billingRetriesConfigUpdating: false,
  errorMessage: null,
  activeOrganizationId: null,
  activeOrganizationIntegrations: [],
  organizations: [],
};

export const vendorOrganizationsReducer: SmartrrVendorSubReducer<IVendorOrganizationsReducerState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "UPDATING_SHIPPING_SETTINGS":
    case "LOADING_USER": {
      return {
        ...state,
        isLoading: true,
      };
    }

    case "LOAD_ADDITIONAL_ORGANIZATIONS": {
      return {
        ...state,
        organizations: action.payload.organizations,
      };
    }

    case "LOADED_VENDOR_PORTAL": {
      const newOrganizations = (action.payload.user?.vendorOrgRoles || []).map(orgRole => orgRole.organization);
      setShopGlobals(
        newOrganizations.find(({ id }) => id === action.payload.activeOrganizationId)?.myShopifyDomain || null
      );

      const newSetup = action.payload.organization?.setup;

      return {
        ...state,
        isLoading: false,
        activeOrganizationId: action.payload.activeOrganizationId || null,
        organizations: newOrganizations,
        ...(newSetup ? { setup: newSetup } : {}),
      };
    }

    case "LOADED_USER": {
      const newOrganizations = (action.payload.user?.vendorOrgRoles || []).map(orgRole => orgRole.organization);
      setShopGlobals(
        newOrganizations.find(({ id }) => id === action.payload.activeOrganizationId)?.myShopifyDomain || null
      );

      return {
        ...state,
        isLoading: false,
        activeOrganizationId: action.payload.activeOrganizationId || null,
        organizations: newOrganizations,
      };
    }

    case "UPDATING_BILLING_TIME": {
      return {
        ...state,
        billingTimeUpdating: true,
      };
    }

    case "ERROR_UPDATING_BILLING_TIME": {
      return {
        ...state,
        billingTimeUpdating: false,
      };
    }

    case "UPDATED_BILLING_TIME": {
      return {
        ...state,
        billingTimeUpdating: false,
        organizations: updateMatchInArray(state.organizations, action.payload.updatedOrganization, org => org.id),
      };
    }

    case "UPDATING_BILLING_RETRIES_CONFIG": {
      return {
        ...state,
        billingRetriesConfigUpdating: true,
      };
    }

    case "ERROR_UPDATING_BILLING_RETRIES_CONFIG": {
      return {
        ...state,
        billingRetriesConfigUpdating: false,
      };
    }

    case "UPDATED_BILLING_RETRIES_CONFIG": {
      return {
        ...state,
        billingRetriesConfigUpdating: false,
        organizations: updateMatchInArray(state.organizations, action.payload.updatedOrganization, org => org.id),
      };
    }

    case "UPDATED_CONFIRMATION_DAYS_CONFIG": {
      return {
        ...state,
        billingRetriesConfigUpdating: false,
        organizations: updateMatchInArray(state.organizations, action.payload.updatedOrganization, org => org.id),
      };
    }

    case "SELECT_ACTIVE_ORGANIZATION": {
      const myShopifyDomain =
        state.organizations.find(({ id }) => action.payload.orgId === id)?.myShopifyDomain || null;
      setShopGlobals(myShopifyDomain); // ensure query param matches for repeatability
      return {
        ...state,
        activeOrganizationId: action.payload.orgId,
      };
    }

    case "LOADING_INTEGRATIONS": {
      return {
        ...state,
        isLoadingIntegrations: true,
      };
    }

    case "ERROR_LOADING_INTEGRATIONS": {
      return {
        ...state,
        isLoadingIntegrations: false,
      };
    }

    case "LOADED_INTEGRATIONS": {
      return {
        ...state,
        isLoadingIntegrations: false,
        activeOrganizationIntegrations: action.payload.integrations,
      };
    }

    case "UPDATED_SHIPPING_SETTINGS": {
      return {
        ...state,
        isLoading: false,
        organizations: updateMatchInArray(state.organizations, action.payload.updatedOrganization, org => org.id),
      };
    }

    default: {
      return state;
    }
  }
};

export function organizationByIdSelector(state: ISmartrrState, orgId: string | null) {
  if (!orgId) {
    return null;
  }

  return state.vendorOrganizations.organizations.find(({ id }) => id === orgId) || null;
}

export function activeOrganizationSelector(state: ISmartrrState) {
  return organizationByIdSelector(state, state.vendorOrganizations.activeOrganizationId);
}

export function useActiveOrganizationSelector() {
  return useSmartrrVendorSelector(activeOrganizationSelector);
}

export function useActiveOrganizationSubSelector<TSelectedWithinOrg>(
  orgSelector: (org: IOrganization | null) => TSelectedWithinOrg | null
): TSelectedWithinOrg | null {
  const finalOrgSelector = useCallback((state: ISmartrrState): TSelectedWithinOrg | null => {
    const activeOrg = activeOrganizationSelector(state);
    return orgSelector(activeOrg);
  }, []);

  return useSmartrrVendorSelector(finalOrgSelector);
}

export function useActiveOrganizationIdSelector() {
  return useActiveOrganizationSubSelector(org => org?.id);
}
