import { TZ } from "@chopshop/tz";
import { DateTime } from "luxon";
import { Frequency, RRule } from "rrule";

import { ISODateString } from "./ISODateString";
import { ISOTimeString } from "./ISOTimeString";
import { IPurchaseState } from "./PurchaseState";
import { ICreateEntity, IShallowEntity, ISharedEntityFields } from "./shared/SharedEntityFields";
import { IShopifyData } from "./ShopifyData";
import { IOrganizationVendorUserRole } from "./VendorUser";

export interface IOrganizationBillingTime {
  billingTime: ISOTimeString;
  billingTimezone: TZ;
}

export interface ISetup {
  /**
   * Note: `selectedSellingPlanId` is a `sellingPlanGroupId`
   */
  selectedSellingPlanId: string;
  hasProductSelected: boolean;
  snippetsThemeId: string;
  renderingThemeId: string;
  isSetupFinished: boolean;
  hasOrgLoggedIn: boolean;
  subscriptionSetup: {
    sellingPlan: {
      date: ISODateString;
      completed: boolean;
      demo: boolean;
    };
    product: {
      date: ISODateString;
      completed: boolean;
      demo: boolean;
    };
  };
  onSiteSetup: {
    smartrrSnippets?: {
      date: ISODateString;
      completed: boolean;
      themeName: string;
      isBannerDismissed: boolean;
    };
    smartrrWidget: {
      completed: boolean;
    };
  };
  billingDays?: string[];
}

export interface IOrganizationBillingRetryConfig {
  numBillingRetries?: number;
  delayBetweenRetries?: number;
}

export enum OutOfStockBehavior {
  CREATE_ALWAYS = "CREATE_ALWAYS",
  CREATE_ONLY_AVAILABLE_SKIP = "CREATE_ONLY_AVAILABLE_SKIP",
  CREATE_ONLY_AVAILABLE_PAUSE = "CREATE_ONLY_AVAILABLE_PAUSE",
}

export interface IOrganizationOutOfStockBehavior {
  outOfStockBehavior: OutOfStockBehavior;
}

export interface IOrganizationConfirmationDays {
  billingConfirmationWaitingDays: number;
}
/**
 * Stores attributes that are relevant to organization account with smartrr.
 * @property unlockedPlanNames - array of account plan names that are unlocked for this organization.
 * Is used to enable access to exclusive plans that are not available to regular stores.
 */
export interface IOrganizationAccount {
  unlockedPlanNames?: string[];
}

export interface IOrgInstaUserData {
  createdDate: string;
  id: string;
  username: string;
  token: string;
  expires_in: number;
  mediaCount: number;
}

export interface IOrgFeatureFlag {
  cachePurchasables: boolean;
  enableNewKlaviyoAPI?: boolean;
  enableAuthIntegrationEvents?: boolean;
}

export interface IOrganization
  extends ISharedEntityFields,
    IOrganizationBillingTime,
    IOrganizationBillingRetryConfig {
  orgName: string;
  logo?: string;
  myShopifyDomain?: string;
  vendorUsers: IOrganizationVendorUserRole[];
  stripeAccessKey?: string;
  shopifyStoreData?: IShopifyData;
  sts: IPurchaseState[];
  numBillingRetries?: number;
  delayBetweenRetries?: number;
  numFailedCyclesBeforeCancel?: number;
  initialSubscriptionImportComplete: boolean;
  account: IOrganizationAccount;
  instagramUserData?: IOrgInstaUserData;
  // IOrganizationBillingTime
  billingTime: ISOTimeString;
  // TODO: openapi
  billingTimezone: TZ | any;
  useBillingPubSub?: boolean;
  outOfStockBehavior?: OutOfStockBehavior;
  hasVisitedRetention?: boolean;
  hasRetentionCancelReasonEnabled?: boolean;
  hasRetentionPauseReasonEnabled?: boolean;
  hasPopulatedPauseReasons?: boolean;
  hasOTPEnabled?: boolean;
  lookerDashboardPrefix?: string;
  featureFlags?: IOrgFeatureFlag;
  setup?: ISetup;
  billingConfirmationWaitingDays?: number;
  rewardsReminderLastCheckedDate?: ISODateString;
}

export type IOrganizationCreate = ICreateEntity<IOrganization>;
export type IOrganizationShallow = IShallowEntity<IOrganization>;

const DEFAULT_BILLING_TIME = { hour: 9, minute: 0, tz: "Africa/Nairobi" };
const dt0 = DateTime.utc(2020);
export const VALID_DAYS = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

export const OrgUtils = {
  getBillingStartDateTime(org: IOrganizationBillingTime): DateTime {
    const [hh, mm, ss] = ISOTimeString.fromString(org.billingTime);

    // Build start date&time in merchant time zone
    let billingStartDateTime = DateTime.fromObject(
      {
        year: dt0.year,
        month: dt0.month,
        day: dt0.day,
        hour: hh,
        minute: mm,
        second: ss,
      },
      {
        zone: org.billingTimezone,
      }
    );

    if (billingStartDateTime.isInDST) {
      // In southern hemisphere they have DST in January, so we step back 6 months to July,
      // because in July they are on "winter" time and have the standard offset
      billingStartDateTime = billingStartDateTime.minus({ month: 6 });
    }

    // Convert to UTC time zone
    return billingStartDateTime;
  },

  getBillingSchedule(org: IOrganizationBillingTime): RRule {
    const startDateTime = OrgUtils.getBillingStartDateTime(org);
    return new RRule({
      dtstart: startDateTime.toJSDate(),
      freq: Frequency.DAILY,
      interval: 1,
    });
  },

  getNextVendorBillingDateTime(org: IOrganizationBillingTime) {
    const billingSchedule = OrgUtils.getBillingSchedule(org);
    return billingSchedule.after(new Date())!;
  },

  /**
   * Calculates merchant's billing date&time (in UTC) for a specified day. The idea is that an event (typically
   * order) could be created before or after merchant's billing time of the day, but this function will return
   * exactly the moment of billing time on that day. Day start and date end are tracked in merchant's time zone.
   *
   * @param dateTime - UTC date&time of event of a day for which we want to calculate billing time.
   * @param org - merchant's billing time settings.
   * @returns merchant's billing date&time in UTC.
   */
  getVendorBillingDateTimeOnDay(dateTime: Date, org: IOrganizationBillingTime) {
    const dateTimeInUtc = DateTime.fromJSDate(dateTime, { zone: "UTC" }); // Reconstruct from UTC time zone
    const merchantDateTime = dateTimeInUtc.setZone(org.billingTimezone); // Convert to merchant time zone

    // Build merchant billing date&time: date comes from event in merchant time zone, while the time comes
    // from merchant's billing time settings
    const billingStartDateTime = OrgUtils.getBillingStartDateTime(org);
    let merchantBillingTime = DateTime.fromObject(
      {
        year: merchantDateTime.year,
        month: merchantDateTime.month,
        day: merchantDateTime.day,
        hour: billingStartDateTime.hour,
        minute: billingStartDateTime.minute,
        second: billingStartDateTime.second,
      },
      {
        zone: billingStartDateTime.zone,
      }
    );

    if (merchantBillingTime.offset !== billingStartDateTime.offset) {
      // smartrr has a rule that billing always runs at standard time, ignoring DST correction. Therefore,
      // if merchant is currently in DST, then we shift the calculated billing time on the day, so that it
      // has the same UTC offset as in the standard time period (this is a tricky ugly workaround which we
      // need while we have billing time in the schedule)
      merchantBillingTime = merchantBillingTime.plus({
        minute: merchantBillingTime.offset - billingStartDateTime.offset,
      });
    }

    // NOTE: resulting UTC date will not be necessarily the same as the input date, for example:
    // if merchant has billing time of 23:00 EST and an order was placed on 2020-01-01 20:00 UTC, then
    // merchant's billing time for the order would be 2020-01-01 23:00 EST which is 2020-01-02 04:00 UTC.
    // So, the function will return 2020-01-02 04:00 UTC.
    return merchantBillingTime.toUTC().toJSDate();
  },

  createDefaultBillingTime(): ISOTimeString {
    return ISOTimeString.toString(DEFAULT_BILLING_TIME.hour, DEFAULT_BILLING_TIME.minute);
  },

  createDefaultBillingTZ(): TZ {
    return DEFAULT_BILLING_TIME.tz as TZ;
  },

  createDefaultBillingSchedule(): RRule {
    // TODO: this function contains a bug: it creates default time in UTC and afterwards converts to the
    // target time zone. It should be the other way around
    return new RRule({
      dtstart: DateTime.utc(dt0.year, dt0.month, dt0.day, DEFAULT_BILLING_TIME.hour, DEFAULT_BILLING_TIME.minute)
        .setZone(DEFAULT_BILLING_TIME.tz)
        .toJSDate(),
      freq: Frequency.DAILY,
      interval: 1,
    });
  },

  skipBillingDate(allowBillingDate: string[], currentDate: Date): boolean {
    const dayIndex = currentDate.getDay();
    const now = VALID_DAYS[dayIndex];
    for (const date of allowBillingDate) {
      if (date === now) {
        return false;
      }
    }
    return true;
  },

  getOrgBillingDays(org: IOrganization): string[] {
    return org ? (org.setup?.billingDays ?? VALID_DAYS) : VALID_DAYS;
  },
};
