import { CurrencyCode } from "@smartrr/shared/currencyCode";

import { BigIntString } from "./BigIntString";
import { ICustomerRelationship, ICustomerRelationshipShallow } from "./CustomerRelationship";
import { ISODateString } from "./ISODateString";
import { IMailingAddressJson } from "./MailingAddress";
import { ISmartrrOrderLineItem } from "./OrderLineItem";
import { IPaymentMethodJson } from "./PaymentMethod";
import { IPurchaseState, IPurchaseStateShallow } from "./PurchaseState";
import { ICreateEntity, IShopifyMirroredEntityFields } from "./shared/SharedEntityFields";
import { IOrderDiscount } from "../interfaces/Discount";
import { PaginationResult } from "../utils/paginatedQuery";

export interface IOrderCSV {
  Order: string | number | undefined;
  Date: ISODateString;
  "Fulfillment status": string;
  Customer: string | undefined;
  Items: string | number | undefined;
  "Total before tax": string | undefined;
  "Cust. currency": string | undefined;
  "Smartrr Order ID": string | number | undefined;
}

export enum ISmartrrOrderStatus {
  OPEN = "open", // being fulfilled / is fulfilled
  CLOSED = "closed", // either fulfilled or "archived" by vendor
  CANCELLED = "cancelled", // cancelled by vendor
}

export function smartrrOrderToText(smartrrOrderStatus: ISmartrrOrderStatus) {
  switch (smartrrOrderStatus) {
    case ISmartrrOrderStatus.CANCELLED: {
      return "Canceled by Shop";
    }
    case ISmartrrOrderStatus.CLOSED: {
      return "Archived";
    }
    case ISmartrrOrderStatus.OPEN: {
      return "Open";
    }
  }
}

export enum BundleEditStatusEnum {
  UNEDITED = "UNEDITED",
  PROCESSING = "PROCESSING",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR",

  NO_BUNDLE = "NO_BUNDLE",
}

export type BundleEditStatus =
  | BundleEditStatusEnum.UNEDITED
  | BundleEditStatusEnum.PROCESSING
  | BundleEditStatusEnum.COMPLETE
  | BundleEditStatusEnum.ERROR
  | BundleEditStatusEnum.NO_BUNDLE;

interface ISmartrrCompanyAndNumberTrackingInfo {
  trackingCompany: string;
  trackingNumbers: string[];
  trackingUrls?: string[];
}
interface ISmartrrUrlTrackingInfo {
  trackingCompany?: string;
  trackingNumbers?: string[];
  trackingUrls: string[];
}
export type ISmartrrTrackingInfo = ISmartrrCompanyAndNumberTrackingInfo | ISmartrrUrlTrackingInfo;

export enum ISmartrrFinancialStatus {
  FAILED_PAYMENT = "failed", // Shopify has all except this one
  PENDING_PAYMENT = "pending",
  AUTHORIZED = "authorized",
  PAID = "paid",
  PARTIALLY_PAID = "partially_paid",
  PARTIALLY_REFUNDED = "partially_refunded",
  REFUNDED = "refunded",
  VOIDED = "voided",
}

export enum ISmartrrFulfillmentStatus {
  UNSHIPPED = "unshipped",
  PARTIALLY_SHIPPED = "partial",
  FULFILLED = "fulfilled",
  RESTOCKED = "restocked",
}

export enum OrderSource {
  Shopify = "Shopify",
  Smartrr = "Smartrr",
  Recharge = "Recharge",
  Other = "Other",
}

export interface ISmartrrTaxLineItem {
  price: number;
  rate: number;
  title: string;
}

export interface ISmartrrShippingLineItem {
  price: number;
  title: string;
  shopifyHandle?: string;
}

export interface ISmartrrRefundLineItem {
  price: number;
  lineItemId?: string;
}

export interface ISmartrrOrderAdjustment {
  price: number;
  reason: string;
}

export interface ISmartrrOrder extends IShopifyMirroredEntityFields, IPriceFieldsSharedWithOrderAndPurchSt {
  shopifyOrderLabel?: string;
  orderProcessedDate: ISODateString;
  orderStatus: `${ISmartrrOrderStatus}` | ISmartrrOrderStatus;
  financialStatus: `${ISmartrrFinancialStatus}` | ISmartrrFinancialStatus;
  fulfillmentStatus: `${ISmartrrFulfillmentStatus}` | ISmartrrFulfillmentStatus;

  deliveryAddress?: IMailingAddressJson; // stored as JSON as Shopify has no ID associated for order address
  deliveryLatitude?: number; // stored at top level for analytics
  deliveryLongitude?: number; // stored at top level for analytics
  deliveryProvinceCode?: string; // stored at top level for analytics
  deliveryCountryCode?: string; // stored at top level for analytics

  orderSource: `${OrderSource}` | OrderSource;
  orderCurrency: CurrencyCode;
  shopIncome: BigIntString;

  totalBeforeDiscountsAndRefunds: BigIntString;
  totalNetBeforeRefunds: BigIntString;

  totalTax: BigIntString;

  totalTip: BigIntString;
  totalRefund: BigIntString;
  totalNet: BigIntString;

  cancelReason?: string;
  cancelledAt?: ISODateString;

  //For analytics
  totalOneTimeShopIncome: BigIntString;
  totalSubscriptionShopIncome: BigIntString;
  totalAddonShopIncome: BigIntString;

  /*
  the items composing the order
  */
  items: ISmartrrOrderLineItem[];
  taxLineItems: ISmartrrTaxLineItem[];
  shippingLineItems: ISmartrrShippingLineItem[];
  discountApplications: IOrderDiscount[];
  refundLineItems: ISmartrrRefundLineItem[];
  refundOrderAdjustments: ISmartrrOrderAdjustment[];
  trackingInfo: ISmartrrTrackingInfo[];

  pmtMthJson?: IPaymentMethodJson;
  custRel?: ICustomerRelationship;
  isGifted?: boolean;

  editedBundleOrder?: boolean;
  containsSmartrrBundle?: boolean;
  bundleEditStatus?: BundleEditStatus;

  orderCycleIndex?: number;
}

export interface ISmartrrOrderFromDb extends ISmartrrOrder {
  // relations
  sts: IPurchaseState[];
}

export interface IPriceFieldsSharedWithOrderAndPurchSt {
  totalFromLineItems: BigIntString;
  totalLineItemsDiscount: BigIntString;
  totalLineItemsAfterDiscount: BigIntString;
  totalShipping: BigIntString;
  totalDiscount: BigIntString;
}

export type ISmartrrOrderCreate = ICreateEntity<ISmartrrOrder>;
export type ISmartrrOrderWithCustomerRelationship = ISmartrrOrder & {
  custRel?: ICustomerRelationshipShallow;
  sts: IPurchaseStateShallow[];
};

export type ISmartrrOrderWithCustomerRelationshipPaginatedResponse =
  PaginationResult<ISmartrrOrderWithCustomerRelationship>;
